<template>
  <section class="section has-bg-img" id="contacto">
    <div class="container">
      <div class="columns">
        <div
          class="column has-background-white is-6-desktop is-offset-3-desktop"
        >
          <div class="has-text-centered is-title ">
            <h3 class="titulo is-size-5">¿Tenés alguna duda? Contactanos</h3>
          </div>
          <div class="form" style="padding: 2rem;">
            <div class="field">
              <label class="label is-small">Nombre</label>
              <div class="control">
                <input
                  type="text"
                  class="input is-radiusless"
                  v-model="form.nombre"
                />
              </div>
            </div>
            <div class="field">
              <label class="label is-small">Email</label>
              <div class="control">
                <input
                  type="text"
                  class="input is-radiusless"
                  v-model="form.email"
                />
              </div>
            </div>
            <div class="field">
              <label class="label is-small">Mensaje</label>
              <div class="control">
                <textarea
                  type="text"
                  class="textarea is-radiusless"
                  v-model="form.message"
                />
              </div>
            </div>
            <div class="field">
              <div class="has-text-centered">
                <button
                  class="button is-radiusless is-link"
                  @click.prevent="sendMessage"
                  :class="{ 'is-loading': sending }"
                >
                  Enviar mensaje
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div class="columns is-centered">
            <div class="column">
              <div class="has-text-centered">
                <a
                  href="https://wa.me/5493516140178"
                  class="button is-radiusless titulo whatsapp-green has-text-white"
                  ><i class="fab fa-whatsapp icono"></i> Contactanos por
                  whatsapp</a
                >
              </div>
            </div>
            <div class="column" style="padding-bottom: 2rem;">
              <div class="has-text-centered">
                <a href="tel:+5493516140178" class="button is-radiusless titulo"
                  ><i class="fas fa-phone-alt icono"></i> Llamanos al (351)
                  614-0178</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: 'https://desarrolladorweb-mailer.netlify.app',
  timeout: 10000,
  // withCredentials: true,
  // transformRequest: [(data) => JSON.stringify(data.data)],
  headers: {
    // Accept: 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
})

export default {
  data() {
    return {
      form: {
        nombre: '',
        email: '',
        message: '',
        to: 'anexxus77@gmail.com',
      },
      sending: false,
    }
  },
  methods: {
    sendMessage() {
      this.sending = true
      if (this.form.nombre.trim().length < 1) {
        alert('Debe completar su nombre')
        return false
      }
      if (!this.validateEmail(this.form.email.trim())) {
        alert('El email no es válido')
        return false
      }
      if (this.form.message.trim().length < 5) {
        alert('Debe completar el mensaje')
        return false
      }
      axiosInstance
        .post('/.netlify/functions/server/mailer/biohard', this.form)
        .then(() => {
          alert('El mensaje se ha enviado correctamente')
          this.form = {
            nombre: '',
            email: '',
            message: '',
          }
        })
        .catch((e) => {
          console.error(e) // eslint-disable-line no-console
          alert('Se produjo un error al intentar enviar el mensaje')
        })
        .finally(() => {
          this.sending = false
        })
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/
      return re.test(email)
    },
  },
}
</script>

<style scoped>
.has-bg-img {
  background: url('../assets/02.jpg') center center;
  background-size: cover;
}
.titulo {
  font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.whatsapp-green {
  background-color: #25d366;
}
.icono {
  margin-right: 1rem;
}
</style>
