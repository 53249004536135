<template>
  <section class="section">
    <div class="container-fluid">
      <div class="columns">
        <div
          class="column is-6-desktop is-offset-3-desktop is-8-tablet is-offset-2-tablet"
        >
          <div class="has-text-centered">
            {{ new Date().getFullYear() }}- Biohard Soluciones Informáticas.
            Todos los derechos reservados
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>
</style>