<template>
  <div class="container">
    <nav class="navbar is-transparent">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img
            src="../assets/logo2.jpg"
            alt="Biohard Soluciones Informáticas"
          />
        </a>
        <div
          class="navbar-burger burger"
          data-target="navbarExampleTransparentExample"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div id="navbarExampleTransparentExample" class="navbar-menu">
        <div class="navbar-start">
          <a class="navbar-item" href="#">
            Inicio
          </a>
          <a class="navbar-item" href="#nosotros">
            Nosotros
          </a>
          <a class="navbar-item" href="#servicios">
            Productos y servicios
          </a>
          <a class="navbar-item" href="#contacto">
            Contacto
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.navbar {
  font-family: "Oswald", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
</style>
