<template>
  <div id="app">
    <NavMain />
    <MainHero />
    <Nosotros />
    <Servicios />
    <Contacto />
    <Footer />
  </div>
</template>

<script>
import NavMain from './components/NavMain'
import MainHero from './components/MainHero'
import Nosotros from './components/Nosotros'
import Servicios from './components/Servicios'
import Contacto from './components/Contacto'
import Footer from './components/Footer'

export default {
  name: 'app',
  components: {
    Contacto,
    Footer,
    MainHero,
    NavMain,
    Nosotros,
    Servicios
  }
}
</script>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
