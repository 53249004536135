<template>
  <section class="hero is-large has-bg-img">
    <div class="hero-body" style="background-color: rgba(0,0,0,0.5);">
      <div class="container zoom has-text-centered">
        <div class="columns is-mobile">
          <div
            class="column is-three-fifths-desktop is-offset-one-fifth-desktop"
          >
            <h2 class="gradient-multiline home-title">
              <span class="title is-1 has-text-white">Biohard</span>
              <span class="is-size-3 is-subtitle is-multiline"
                >Soluciones Informáticas</span
              >
            </h2>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.has-bg-img {
  background: url("../assets/01.jpg") center center;
  background-size: cover;
}
.zoom {
  animation: zoominoutsinglefeatured 15s forwards;
}
@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1.2, 1.2);
  }
}
.home-title {
  font-family: "Montserrat", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.gradient-multiline {
  position: relative;
  padding: 3rem;
  color: white;
  text-align: center;
  line-height: 1.5em;
  overflow: hidden;
  text-shadow: 2px 2px 4px #000000;
}
.home-title span {
  position: relative;
  overflow: hidden;
  display: block;
  line-height: 1.2;
}

.home-title span::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
  animation: a-ltr-after 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
  transform: translateX(-101%);
}

.home-title span::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  animation: a-ltr-before 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
  transform: translateX(0);
}

.home-title span:nth-of-type(1)::before,
.home-title span:nth-of-type(1)::after {
  animation-delay: 1s;
}

.home-title span:nth-of-type(2)::before,
.home-title span:nth-of-type(2)::after {
  animation-delay: 1.5s;
}

.is-title,
.is-subtitle {
  font-family: "Oswald", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 900;
  margin-top: 2rem;
}

@keyframes a-ltr-after {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(101%);
  }
}

@keyframes a-ltr-before {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}
</style>