<template>
  <section class="section" id="servicios">
    <div class="container">
      <div>
        <h1 class="is-title is-size-2 has-text-centered titulo" style="margin-bottom: 3rem;">Productos y servicios</h1>
      </div>
      <div class="columns">
        <div class="column">
          <div class="item-servicio">
            <h2 class="is-size-5 titulo"><i class="fas fa-laptop-medical icono"></i> Venta de hardware e insumos</h2>
            <div class="subtitulo">
              <p class="parrafo">Proveemos de insumos informáticos a usuarios, empresas, casas de computación, tiendas online, etc. localmente como en el interior del país.</p>
              <p class="parrafo">El insumo es un bien consumible utilizado en la producción de otro bien. Este término, equivalente en ocasiones al de materia prima.</p>
              <p class="parrafo">Insumos informáticos o insumos para computación se refiere a todos los componentes relativos a las computadoras o a los periféricos que permiten obtener productos partiendo de materias primas. Notebooks, PC, AIO, monitores, consolas de juegos, máquinas de fotos, filmadoras, GPS, etc.</p>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="item-servicio">
            <h2 class="is-size-5 titulo"><i class="fas fa-tools icono"></i> Asistencia técnica</h2>
            <div class="subtitulo">
              <p class="parrafo">Solucionamos antes de 24 horas sus problemas de hardware, software, virus, troyanos, spyware...</p>
              <p class="parrafo">Nos especializamos en entornos de empresa y redes, si tiene una red que no funciona, o tiene problemas con la conexión a Internet, su wireless o cualquier otra eventualidad informática nosotros podemos ayudarle.</p>
              <p class="parrafo">Reparamos motherboards y notebooks, contamos con servicio tercerizado de rebaling con garantía.</p>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="item-servicio">
            <h2 class="is-size-5 titulo"><i class="fas fa-network-wired icono"></i> Redes</h2>
            <div class="subtitulo">
              <p class="parrafo">Cableados, routers, VPN, servidores, wireless, telefonía...instalamos, configuramos y mantenemos todo tipo de redes.</p>
              <p class="parrafo">Nos especializamos en asistencia integral a pequeñas y medianas empresas, abarcando desde cableado estructurado (telefonía, datos..) hasta las últimas novedades (wireless, Pda, VoIP...), utilizando tecnologías de última generación y personal altamente calificado en cada área.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="item-servicio">
            <h2 class="is-size-5 titulo"><i class="fas fa-cogs icono"></i> Mantenimiento</h2>
            <div class="subtitulo">
              <p class="parrafo">Nuestro objetivo es que el cliente pueda "olvidarse" de su sistema informático. Estudio previo y puesta a punto. Revisamos, optimizamos y ajustamos su sistema para poder darle el mejor servicio.</p>
              <p class="parrafo">Somos su empresa de confianza que detecta sus problemas y los soluciona antes que usted los sufra.</p>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="item-servicio">
            <h2 class="is-size-5 titulo"><i class="fas fa-graduation-cap icono"></i> Consultoría</h2>
            <div class="subtitulo">
              <p class="parrafo">Nuestra experiencia y conocimientos están a su servicio. Usted plantea el problema, nosotros le damos solución.</p>
              <p class="parrafo">Nuestra calidad está asegurada por el amplio abanico de conocimientos y experiencias acumulados entre todos nuestros técnicos.</p>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="item-servicio">
            <h2 class="is-size-5 titulo"><i class="fas fa-video icono"></i> Seguridad y Vigilancia</h2>
            <div class="subtitulo">
              <p class="parrafo">Instalamos alarmas domiciliarias, CCTV y cámaras IP para poder vigilar su empresa, sus empleados, niñeras, hijos, etc. desde otra pc o notebook en cualquier parte del mundo.</p>
              <p class="parrafo">También configuramos en sistemas android, blackberry, wince para que pueda ver desde su IPAD o celular.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.titulo {
  font-family: 'Oswald', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.subtitulo {
  font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.item-servicio .titulo {
  margin-bottom: 1.5rem;
}
.parrafo {
  margin-bottom: 1rem;
}
.icono {
  margin-right: 1rem;
}
</style>
