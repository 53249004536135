<template>
  <div class="container" style="margin-top: 1rem;" id="nosotros">
    <div class="columns">
      <div class="column is-6-desktop is-offset-3-desktop">
        <div class="notification is-radiusless">
          <h1
            class="is-subtitle is-size-4 has-text-centered titulo"
            style="margin-bottom: 2rem;"
          >
            Acerca de Biohard Soluciones Informáticas
          </h1>
          <div class="subtitulo">
            <p class="parrafo">
              Somos una empresa joven, dedicada a la Informática, a las
              telecomunicaciones y la tecnología.
            </p>
            <p class="parrafo">
              Nuestra Visión es posicionarnos en el mercado como una empresa
              líder, gracias a la excelencia de nuestros productos y servicios.
            </p>
            <p class="parrafo">
              Nuestra Misión es satisfacer las necesidades y exigencias de
              nuestros clientes, ofreciendo los mejores productos y servicios,
              con los mejores precios, apoyándonos en la excelencia y calidad.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.titulo {
  font-family: "Oswald", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.subtitulo {
  font-family: "Montserrat", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.parrafo {
  margin-bottom: 1rem;
  text-align: justify;
}
</style>
